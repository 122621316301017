<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select v-model="query.type" clearable placeholder="站点类型" class="filter-item" style="width: 200px">
      <el-option v-for="item in queryTypeOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.managerName" clearable placeholder="销售名称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>

    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  components: {
    searchCommunity,
  },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 2, display_name: '标准版' },
        { key: 3, display_name: '跟单版' },
        { key: 4, display_name: '跨平台API服务' },
        { key: 5, display_name: '多账户管理系统MAM' },
        { key: 6, display_name: '客户管理系统CRM' },
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
