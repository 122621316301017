<template>
  <div class="app-container">
    <eHeader ref="header" :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" border style="width: 100%;">
      <el-table-column prop="siteId" label="站点ID" width="60"/>
      <el-table-column label="站点类型">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="siteName" label="站点名称"/>
      <el-table-column prop="managerName" label="销售名称">
        <template slot-scope="scope">
          <span style="white-space: pre;">{{scope.row.managerName || '-'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="siteUserName" label="站点联系人">
        <template slot-scope="scope">
          <template v-if="scope.row.siteUserName">
            <div>{{scope.row.siteUserName}}</div>
            <div>{{scope.row.phone}}</div>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="userNumber" label="注册用户数"/>

      <el-table-column
        label="交易员"
        align="center"
      >
        <el-table-column prop="signalNumber" label="账户数">
          <template slot-scope="scope">
            <span>{{scope.row.signalNumber}}/{{scope.row.masterMaxNumber < 0 ? '不限': scope.row.masterMaxNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="signalBalance" label="余额(usd)"/>
        <el-table-column prop="signalVolume" label="累积交易手数"/>
        <el-table-column prop="signalProfit" label="累积盈亏"/>
      </el-table-column>
      <el-table-column
        label="跟随者"
        align="center"
      >
        <el-table-column prop="copyNumber" label="账户数">
          <template slot-scope="scope">
            <span>{{scope.row.copyNumber}}/{{scope.row.copyMaxNumber < 0 ? '不限': scope.row.copyMaxNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="copyBalance" label="余额(usd)"/>
        <el-table-column prop="copyVolume" label="累积交易手数"/>
        <el-table-column prop="copyProfit" label="累积盈亏"/>
      </el-table-column>
      <el-table-column prop="siteName" label="搭建时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime)}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/community/siteStatistics/header'
  export default {
    components: { eHeader },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = '/community/crm/srSiteList'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort,
          siteType: this.query.type,
          managerName: this.query.managerName,
        };
        if (this.$refs.header.$refs.searchcommunity.siteId) {
            this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        return true
      },
      getType(row){
        switch (row.siteType) {
          case 2:
            return '标准版';
          case 3:
            return '跟单版';
          case 4:
            return '跨平台API服务';
          case 5:
            return '多账户管理系统MAM';
          case 6:
            return '客户管理系统CRM';
        }
      }
    }
  }
</script>

<style scoped>

</style>
